<template>
  <div id="ekkTable">
    <div class="table-box">
      <el-table
        header-row-class-name="table-header"
        :data="tableData"
        style="width: 100%"
        empty-text=" "
        ref="tableRef"
        @sort-change="sortChange"
        @row-click="getSelectData"
        @selection-change="selectionChange"
      >
        <el-table-column
          v-if="selection"
          type="selection"
          min-width="50"
        ></el-table-column>
        <el-table-column
          v-for="(item, index) in options"
          v-show="item.hide ? Boolean(false) : Boolean(true)"
          :key="index"
          :prop="item.prop"
          :align="
            item.center == 'right' ? 'right' : item.center ? 'center' : ''
          "
          :align-header="item.center ? 'center' : ''"
          :label="item.label"
          :show-overflow-tooltip="
            item.overflow ? Boolean(true) : Boolean(false)
          "
          :sortable="item.sort"
          :min-width="item.width || ''"
        >
          <!-- :show-overflow-tooltip="item.overflow ? Boolean(true) : Boolean(false)" -->
          <!-- :render-header ="item.render_header ? renderHeader : null" -->
          <template slot-scope="scope">
            <div
              class="m-2"
              style="display: flex; flex-wrap: wrap;"
              v-if="item.prop == 'memo'"
            >
              <el-tag
                :key="tag"
                v-for="tag in scope.row.memo"
                closable
                :disable-transitions="false"
                @close="handleClose(tag, scope.row.memo, scope.row.openid)"
                :style="`margin-right: 5px; display: ${tag.length == 0 ? 'none' : 'flex'};margin-top:5px;align-items: center;`"
            
              >
              <el-tooltip :content="tag" placement="top">
                <span style="max-width: 200px;overflow: hidden;text-overflow: ellipsis;">{{ tag }}</span>
              </el-tooltip>
              </el-tag>
              <el-input
                class="input-new-tag"
                v-if="inputVisible && openid == scope.row.openid"
                v-model="inputValue"
                ref="saveTagInput"
                size="small"
                style="margin-top: 5px; width: 150px;"
                @keyup.enter.native="handleInputConfirm(scope.row.memo, scope.row.openid)"
                @blur="handleInputConfirm(scope.row.memo, scope.row.openid)"
              >
              </el-input>
              <el-button
                v-else
                class="button-new-tag"
                size="small"
                @click="showInput(scope.row.openid)"
                style="margin-top: 5px;"
                >+ 新标签</el-button
              >
            </div>
            <div
              class="m-2"
              style="display: flex; flex-wrap: wrap;"
              v-else-if="item.prop == 'tags'"
            >
              <el-tag
                :key="tag"
                v-for="tag in scope.row.tags"
                :style="`margin-right: 5px; display: ${tag.length == 0 ? 'none' : 'flex'};margin-top:5px;align-items: center;`"
            
              >
              <el-tooltip :content="tag" placement="top">
              <span style="max-width: 100px;overflow: hidden;text-overflow: ellipsis;">{{ tag }}</span>
              </el-tooltip>
              </el-tag>


            
            </div>
            <span v-else v-show="item.prop && !item.src && !item.signal">{{
              scope.row[item.prop]
            }}</span>
            <el-button
              v-show="
                !item.prop &&
                  (it.name ||
                    (it.condition && it.conditionList[scope.row[it.condition]]))
              "
              v-for="(it, idx) in item.slot"
              :key="idx"
              @click="handle(scope.row, it.type)"
              type="text"
              size="small"
            >
              {{
                it.condition
                  ? it.conditionList[scope.row[it.condition]]
                  : it.name
              }}
            </el-button>
            <img
              v-if="item.src"
              class="table-img"
              :src="
                scope.row[item.prop] ||
                  require('@/assets/images/default_avatar_90.png')
              "
              alt=""
            />
            <img
              v-if="item.signal"
              class="signal-img"
              :src="
                require('../assets/images/signal/signal_' +
                  scope.row[item.prop] +
                  '.png')
              "
              alt=""
            />
          </template>
        </el-table-column>
      </el-table>
      <div class="feedback" v-show="feedback">
        <i
          v-show="feedback === 'loading'"
          class="load-icon-item el-icon-loading"
        ></i>
        <span v-if="feedback === 'error' || feedback === 'empty'">{{
          feedback === "error"
            ? "数据加载失败，请稍后重试"
            : feedback === "empty"
            ? "暂无数据"
            : ""
        }}</span>
      </div>
    </div>
    <div
      class="page-box"
      :style="page.style ? 'padding-top:0px;' : ''"
      v-if="page.total > 0"
    >
      <el-pagination
        @current-change="handlePage"
        :current-page.sync="page.cur"
        :page-size="page.size"
        layout="total, prev, pager, next,jumper"
        :total="page.total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: "ekk-table",
  props: {
    tableData: {
      // 表格数据
      type: Array,
      default: () => {
        return [];
      },
    },
    /*
     *   表格配置【options】：接受一个<<对象数组>>
     *   字段说明：
     *   【hide】 => Boolean（非必填）=> default: false，为<true>时该列隐藏
     *   【label】 => String（必填） => 表头名称
     *   【prop】 => String（非必填） => 对应单元格的字段 => 有【slot】时不填，否则必填
     *   【render】 => Array（非必填） => 根据单元格内容给出对应的类名
     *              接受一个<<对象数组>>
     *              字段说明：
     *               cursor
     *    【slot】 => Array（非必填） => 对应单元格的操作
     *              接受一个<<对象数组>>
     *              字段说明：
     *              【type】 => String（必填） => 操作类型
     *              【name】 => String（必填） => 名字
     *    【sort】 => String => 排序 custom
     *    【center】 => Boolean => default: false => 为<true>时该列居中对齐, 默认局左对齐
     *    【overflow】 => Boolean => default: false => 为<true>时内容溢出隐藏
     *    【min-width】 => Number => 列宽度
     */
    options: {
      type: Array,
      default: () => {
        return [];
      },
    },
    page: {
      // 页数配置
      type: Object,
      default: () => {
        return {
          total: 0, // 数据量
          size: 20, // 每页数据量
          cur: 1, // 当前页
        };
      },
    },
    feedback: {
      // 用户体验反馈 => 'loading'-加载中， 'empty'-暂无数据,  'error'-获取数据失败
      type: String,
      default: "",
    },
    selection: {
      // 是否开启复选框
      type: Boolean,
      default: false,
    },
    bodyHeight: Number, // 限制table-body高度
    emptySelectionData: {
      // 清空复选数据
      type: Boolean,
      default: false,
    },
    cctest: {
      type: Array,
      default: () => {
        return [];
      },
    },
    selectList: {
      type: Array,
      default: () => {
        return [];
      },
    },
    testData: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      selectData: [], // 复选数据
      inputVisible: false,
      inputValue: "",
      openid: "",
    };
  },
  mounted() {},
  updated() {
    // this.$nextTick( () =>{
    //     $('.init-image').viewer({
    //         interval:3000,
    //         navbar:false,
    //     });
    // })
  },
  watch: {
    // 清空复选数据
    emptySelectionData(val) {
      if (val) this.selectData = [];
    },

    testData(val) {
      if (val) {
        this.selectData = val;
        this.toggleSelection(this.selectData, 0);
      }
    },
    // val [{page: page.cur, data: select_arr}]
    cctest(val, old) {
      if (val.length > 0) {
        // 1、找出当前selectData是否有该页的数据
        let select_data = this.selectData,
          cur_page = this.page.cur;

        let val_target = val.find((item) => item.page == cur_page);
        let val_barcode = ((val_target && val_target.data) || []).map(
          (item) => item.barcode
        );

        if (select_data.length > 0) {
          // let select_target = select_data.find(item => item.page == cur_page )
          let select_index = select_data.findIndex(
            (item) => item.page == cur_page
          );
          // 找出data
          if (!val_target) return;
          this.selectData[select_index >= 0 ? select_index : 0] = val_target;
        } else {
          this.selectData = val;
        }

        if (val_barcode.length == 0) return;
        this.tableData.forEach((item, index) => {
          if (val_barcode.includes(item.barcode)) {
            this.$refs.tableRef.toggleRowSelection(this.tableData[index], true);
          }
        });
      } else {
        this.selectData = [];
      }
    },
  },
  methods: {
    handleClose(tag, val, openid) {
      let memo = val;
      memo.splice(memo.indexOf(tag), 1);

      axios
        .post("/user_memo", {
          memo: memo.join(","),
          openid: openid,
        })
        .then((res) => {

        });
    },

    showInput(openid) {
      this.inputVisible = true;
      this.openid = openid;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm(val, openid) {
      let inputValue = this.inputValue;
      if (inputValue) {
        let memo = val;
        memo.push(inputValue);

        axios
          .post("/user_memo", {
            memo: memo.join(","),
            openid: openid,
          })
          .then((res) => {

          });
      }
      this.inputVisible = false;
      this.inputValue = "";
    },

    //标题头部tip
    // render 事件
    renderHeader(h, { column }) {
      // h即为cerateElement的简写，具体可看vue官方文档
      // return (
      //     <el-tooltip
      //         class="item"
      //         effect="dark"
      //         placement="top">
      //         <div slot="content">{this.$t('message.tips.tipSameGroup')}</div>
      //         <span>{column.label}<i class="el-icon-question" style="color:#409eff;margin-left:5px;"></i></span>
      //     </el-tooltip>
      // );
    },
    handleCol(item, prop) {
      this.$emit("handleCol", item, prop);
    },
    // 操作
    handle(row, type) {
      // console.log('表格操作',this.$listeners)
      this.$emit("handle", row, type);
    },

    // 排序
    sortChange({ prop, order }) {
      this.$emit("handleSort", prop, order);
    },

    //类名
    renderClass(row, classes) {
      let myClass = [];
      if (!classes) return;
      classes.forEach((item) => {
        myClass.push(item);
      });
      return myClass.join(" ");
    },

    // 切换页数
    handlePage(val) {
      const that = this;
      that.$emit("handlePage", val);
      // 若开启复选，每次切换页码查找当页是否有已勾选的选项
      if (that.selection) {
        const selectData = that.selectData;
        let n = 0;
        const timer = setInterval(() => {
          n++;
          if (that.tableData.length > 0) {
            if (selectData.length > 0) that.toggleSelection(selectData, val);
            clearInterval(timer);
          } else {
            if (n > 6) clearInterval(timer);
          }
        }, 500);
      }
    },

    // 勾选，并保存数据
    selectionChange(val) {
      const that = this,
        cur = that.page.cur;
      let selectData = that.selectData;
      // 数据为空，直接push
      if (selectData.length === 0) {
        selectData.push({
          page: cur,
          data: val,
        });
      } else {
        // 数据不为空，判断当前页是否有数据，有就替换，没有就push
        let index = 0;
        const paged = selectData.some((item, idx) => {
          if (item.page === cur) {
            index = idx;
            return true;
          }
        });
        if (paged) {
          if (val.length > 0) selectData[index].data = val;
        } else {
          selectData.push({
            page: cur,
            data: val,
          });
        }
      }
      that.selectData = selectData;
      that.$emit("select", selectData);
    },

    //某行点击
    getSelectData(row, event, column) {
      this.tableData.forEach((item, index) => {
        if (row == item) {
          // this.toggleSelection(this.tableData[index]);
          this.$nextTick(() => {
            this.$refs.tableRef.toggleRowSelection(this.tableData[index]);
          });
        }
      });
    },

    // 查找当页是否有已勾选的选项, 有就勾选
    toggleSelection(selectData, val) {
      let index = 0;
      const that = this,
        paged = selectData.some((item, idx) => {
          if (item.page === val) {
            index = idx;
            return true;
          }
        });
      if (paged && selectData[index].data.length > 0) {
        let useIdx = [],
          useData = [];
        that.tableData.forEach((item, idx) => {
          selectData[index].data.forEach((it) => {
            if (JSON.stringify(item) === JSON.stringify(it)) useIdx.push(idx);
          });
        });
        useIdx.forEach((item) => {
          that.$refs.tableRef.toggleRowSelection(that.tableData[item]);
        });
      }
    },
  },
};
</script>
<style lang="scss" scope></style>
